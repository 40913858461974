// gatsby-browser.js
import "./src/styles/global.css";

export const onInitialClientRender = () => {
  const link = document.createElement("link");
  link.href = "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);
};

